import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ConcertsService } from 'src/app/concerts.service';
import { SidenavService, Submenu } from 'src/app/sidenav.service';

@Component({
    selector: 'app-historique',
    templateUrl: './historique.component.html',
    styleUrls: ['./historique.component.scss'],
    standalone: false
})
export class HistoriqueComponent implements OnInit {
  COLUMNS_NUMBER = 3;

  filterCplc = true;

  cplcFlyersUrls: string[];
  amisFlyersUrls: string[];

  constructor(
    private titleService: Title,
    public sidenavService: SidenavService,
    public concertsService: ConcertsService
  ) {
    this.titleService.setTitle('Historique des concerts');

    this.concertsService.concertsFlyers$.subscribe((concertsFlyers) => {
      this.cplcFlyersUrls = concertsFlyers.cplc.reverse();
      this.amisFlyersUrls = concertsFlyers.amis.reverse();
    });
  }

  ngOnInit(): void {
    this.sidenavService.setActiveSubmenu(Submenu.CONCERTS);
  }
}
