<div class="text-center w-75 mx-auto">
  @if (false) {
    <p>
      Le programme de cette saison 2024-2025 a été élaboré à Vercorin début juillet lors de notre traditionnel stage
      d'été,
      <br />avec la participation de David Mercier, trompettiste.
    </p>
  } @else {
    <p>
      La saison 2025-2026 sera exceptionnnelle car le CPLC célebrera son 30ème anniversaire avec la participation du
      <a href="https://www.tchikiduo.com" alt="Tchiki Duo Project">Tchiki Duo</a>! <br />Comme habituellement, trois
      concerts seront donnés à Vercorin et au Casino-Théâtre de Rolle en juillet à l'issue de notre stage d'été.
      <br />Mais avant cela, la Passion sera à nouveau jouée pour Pâques (flyers ci-dessous).
    </p>
  }

  @if (false) {
    <p>
      Ces pièces seront rejouées le 9 février 2025 lors de notre traditionnel concert d'hiver à Chéserex (flyer
      ci-dessous).
    </p>
  }
</div>

<div class="d-flex mx-auto mt-4 align-items-center">
  <picture class="mx-auto py-4 d-block">
    <source srcset="{{ 'assets/prochain-concert.jpg?preventCaching=' + buildHash }}" />
    <img
      src="{{ 'assets/prochain-concert.jpg?preventCaching=' + buildHash }}"
      alt="Flyer du prochain concert du CPLC"
      width="400px"
    />
  </picture>
</div>

<div class="text-center w-75 mx-auto">
  <p>
    Si toutefois vous ne pouvez pas assister à l’un de nos concerts et souhaitez nous soutenir, <br />ci-dessous nos
    coordonnées bancaires:
  </p>
  <div class="row">
    <div class="col-6">
      <p>
        Centre de Percussions de la Côte<br />
        p/a Claude Meynent<br />
        Rte du Creux-du-Mas 9<br />
        1185 Mont-sur-Rolle
      </p>
    </div>
    <div class="col-6">
      <p>
        CH58 0024 3243 G770 3199 0<br />
        UBS Switzerland AG, CH 8098 Zürich
      </p>
    </div>
  </div>
</div>
